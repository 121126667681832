<template>
  <div class="activity-card card table" :class="{ 'is-close': collapse }">
    <header class="card-header">
      <p class="card-header-title">
        <span>Atividades</span>
      </p>
      <div class="card-header-icon" aria-label="collapse card" @click="collapse = !collapse">
        <i class="lni" :class="collapse ? 'lni-chevron-up' : 'lni-chevron-down'" />
      </div>
    </header>
    <div class="card-content">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Título</th>
            <th class="has-text-right">Criada Em</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(activity) in classActivities"
            :key="activity.id"
          >
            <td>
              <router-link
                :to="`/p/s/atividades/${activity.id}`"
              >
                {{ activity.name }}
              </router-link>
            </td>
            <td class="has-text-right">
              {{ activity.createdAt | formatDate }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { format } from 'date-fns'

export default {
  name: 'StudentActivityCard',
  props: {
    idClass: {
      type: Number,
      required: true
    }
  },
  created () {
    api.get(`/classes/${this.idClass}/activities`).then(res => {
      if (res.status === 200) {
        this.classActivities = res.data
      } else {
        this.error = 'Não foi possível carregar as atividades'
      }
    })

    api.get('/activities').then(res => {
      this.activities = res.data
    })
  },
  data () {
    return {
      classActivities: [],
      collapse: false,
      cloneMode: false,
      error: null,
      activities: [],
      idActivity: 0,
      enviando: false
    }
  },
  filters: {
    formatDate (value) {
      if (value === '' || value === undefined) return
      return format(new Date(value), 'd/MM/y')
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  &.is-close {
    .card-content {
      max-height: 0;
      padding: 0;
      overflow: hidden;
    }
  }
}
</style>
