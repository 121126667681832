<template>
  <layout page="student-class">
    <page-title icon="lni lni-school-bench-alt" :breadcrumb="breadcrumb">{{ this.class.name }}</page-title>
    <div>
      <activity-card :idClass="parseInt(this.class.id)" />
    </div>
  </layout>
</template>

<script>
import api from '@/api'
import { DASHBOARD, CLASS_LIST, CLASS } from '@/config/breadcrumb/student'
import Layout from '@/layout/admin/Layout'
import PageTitle from '@/components/admin/PageTitle'
import ActivityCard from '../activity/ActivityCard'

export default {
  name: 'StudentClass',
  components: {
    Layout,
    PageTitle,
    ActivityCard
  },
  created () {
    this.class.id = this.$route.params.id

    api.get(`/classes/${this.class.id}`).then(res => {
      if (res.status === 404) {
        this.router.push('/t/s/turmas')
      }

      this.class = res.data
    })
  },
  data () {
    return {
      class: {
        id: 0,
        name: 'Carregando...'
      }
    }
  },
  computed: {
    breadcrumb () {
      return [
        DASHBOARD(),
        CLASS_LIST(),
        CLASS(this.class, true)
      ]
    }
  }
}
</script>
